<div class="container mt-5">

    <h1>API Key</h1>

    <p>
        Click below to get a Client ID/Client Secret to be used for calling the SEC API
    </p>

    <button type="button" class="btn" (click)="createm2m()">Create M2M Client </button>
    <p>Client ID: {{clientId}}</p>
    <p>Client Secret: {{clientSecret}}</p>
    
</div>