<h1>{{org?.display_name}}</h1>
<img src={{org?.branding?.logo_url}}>


<h1>GUID: {{guid}}</h1>

<h2>Your Organizations</h2>

<table  mat-table [dataSource]="orgs" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}}
      </td>
    </ng-container>

    Name Column
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id}}
        </td>
    </ng-container>

              <!-- Name Column -->
    <ng-container matColumnDef="user_id">
        <th mat-header-cell *matHeaderCellDef> Slogan </th>
        <td mat-cell *matCellDef="let element"> {{element.metadata.slogan}}
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="['name', 'email', 'user_id']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['name', 'email', 'user_id'];"></tr>
  </table>
